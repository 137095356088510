import React from 'react';
import {
  Grid,
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  Box,
} from '@mui/material';
import Footer from './footer';
import Header from './header';

const Login = () => {
  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle login logic here
  };

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      direction="column"
      style={{
        minHeight: '100vh',
        background: 'url(/bg.png)',
        padding: '20px',
      }}
    >
      <Header />
      <Grid
        container
        sx={{
          backgroundColor: '#ffffff1f',
          boxShadow: `3px 9px 16px hsla(0,1%,59%,.4),-3px -3px 10px hsla(0,0%,100%,.06),inset 14px 14px 26px rgba(0,0,0,.3),inset -3px -3px 15px hsla(0,9%,79%,.05)`,
        }}
        justifyContent="space-between" // Center the form when the image is hidden
        alignItems="center"
        xs={10}
        borderRadius="20px"
      >
        {/* Left Side: Poster Image - Hide on small screens */}
        <Grid
          item
          sm={6} // Display image on small screens and above
          md={6} // Medium and large screens
          display={{ xs: 'none', sm: 'block' }} // Hide image on extra-small screens (xs)
          justifyContent="flex-start"
        >
          <img
            src="/ch_poster.png"
            alt="Poster"
            style={{
              width: '100%',
              maxWidth: '700px',
              height: 'auto',
            }}
          />
        </Grid>

        {/* Right Side: Login Form */}
        <Grid
          item
          xs={12} // Full width on small screens
          sm={6} // Half width on medium and large screens
          md={4} // 4/12 width on medium screens
          padding="1rem"
        >
          <Card
            style={{
              minHeight: '60vh',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <CardContent>
              {/* Center the logo */}
              <Box display="flex" justifyContent="center" mb={2}>
                <img
                  src="/chathive-p.png"
                  alt="chathive"
                  width="100px"
                  height="auto"
                />
              </Box>
              <Typography
                variant="h4"
                component="h1"
                gutterBottom
                align="center"
              >
                Login
              </Typography>
              <form onSubmit={handleSubmit}>
                <Box mb={2}>
                  <TextField
                    fullWidth
                    label="Email"
                    variant="outlined"
                    type="email"
                    required
                  />
                </Box>
                <Box mb={2}>
                  <TextField
                    fullWidth
                    label="Password"
                    variant="outlined"
                    type="password"
                    required
                  />
                </Box>
                <Box textAlign="center" mb={2}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    size="large"
                    fullWidth
                  >
                    Login
                  </Button>
                </Box>
                <Box textAlign="center">
                  <Typography variant="body2">
                    Don't have an account? <a href="/signup">Sign up</a>
                  </Typography>
                </Box>
              </form>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Footer />
    </Grid>
  );
};

export default Login;

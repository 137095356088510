import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Login from './screens/login';
import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import SignUp from './screens/signup';

function App() {
  const theme = createTheme({
    palette: {
      primary: {
        main: '#3d215b', // Set your primary color
      },
    },
  });
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<SignUp />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
